<!--<template>
  <v-data-table :headers="headers" :items="hrs" sort-by="calories" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Manage People</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <div>
          <v-select :items="hr_type_filters" label="HR type" v-model="hr_type_filter">
          </v-select>
        </div>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.name" label="Person Name"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="editedItem.designation" label="Designation"></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-select :items="items" label="HR type" v-model="editedItem.hr_type"></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-if="editedItem.hr_type == 'teacher'" v-model="editedItem.subject" label="Teacher subject"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea v-if="editedItem.hr_type == 'advisor'" v-model="editedItem.advice" label="Advice"></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input v-model="photo" accept="image/*" label="Upload photo" clearable></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.photo="{ item }">
      <v-img :aspect-ratio="800/450" :width="100" :src="item.photo"></v-img>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)" color="blue">mdi-pencil</v-icon>
      <v-icon small color="red" @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>-->
<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Manage People</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <div>
        <v-select :items="hr_type_filters" label="HR type" v-model="hr_type_filter">
        </v-select>
      </div>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.name" label="Person Name"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="editedItem.designation" label="Designation"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select :items="items" label="HR type" v-model="editedItem.hr_type"></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-if="editedItem.hr_type == 'teacher'" v-model="editedItem.subject" label="Teacher subject"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-textarea v-if="editedItem.hr_type == 'advisor'" v-model="editedItem.advice" label="Advice"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-file-input v-model="photo" accept="image/*" label="Upload photo" clearable></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>


    <v-list two-line v-if="hrs && hrs.length">
      <v-card>
        <draggable
            v-model="sortedHrs"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
        >
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <div v-for="(hr, index) in sortedHrs" :key="uniqueId+index">
              <v-list-item :ripple="false" :key="index+hr.id+uniqueId">

                <template v-slot:default>
                  <v-list-item-avatar rounded min-width="80" min-height="60">
                    <v-img
                        :alt="`${hr.name}`"
                        :src="hr.photo"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="hr.name"></v-list-item-title>
                    <v-list-item-title>
                      Hr Type : <strong>{{ hr.hr_type }}</strong>
                    </v-list-item-title>
                    <v-list-item-title v-if="hr.designation">
                      Designation: <strong>{{ hr.designation }}</strong>
                    </v-list-item-title>
                    <v-list-item-title v-if="hr.advice">
                      Advice: <strong>{{ hr.advice }}</strong>
                    </v-list-item-title>
                    <v-list-item-title v-if="hr.subject">
                      Advice: <strong>{{ hr.subject }}</strong>
                    </v-list-item-title>

                    <v-list-item-subtitle
                        class="text--primary"
                        v-text="hrs.sub_title"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click="editItem(hr)">
                      <v-icon
                          color="blue lighten-1"
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn icon @click="deleteItem(hr)">
                      <v-icon
                          color="red darken-3"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
              <v-divider
                  v-if="index < hrs.length - 1"
                  :key="uniqueId+index"
              ></v-divider>
            </div>
          </transition-group>
        </draggable>
      </v-card>
    </v-list>
    <div v-else>
      <v-card class="text-center my-2" v-if="!loading">
        No data found
      </v-card>
    </div>
  </div>
</template>


<script>
import draggable from "vuedraggable";
import axios from "axios";
import {v4 as uuidv4} from "uuid";

export default {
  name: "Previewhrs",
  components: {draggable},
  data: () => ({
    items: ['guest', 'advisor', 'founder', 'teacher', 'team-members'],
    dialog: false,
    dialogDelete: false,
    hr_type_filter: 'all',
    hr_type_filters: ['all', 'guest', 'advisor', 'founder', 'teacher'],
    photo: null,
    sortedHrs: [],
    drag: false,
    selected: false,
    loading: false,
    headers: [
      {
        text: 'Photo',
        align: 'start',
        sortable: false,
        value: 'photo',
      },
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Type',
        value: 'hr_type'
      },
      {
        text: 'Designation',
        value: 'designation'
      },
      {
        text: 'Subject',
        value: 'subject',
      },
      {
        text: 'Advice',
        value: 'advice',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      },
    ],
    hrs: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      designation: '',
      advice: '',
      subject: '',
      hr_type: '',
    },
    defaultItem: {
      name: '',
      designation: '',
      advice: '',
      subject: '',
      hr_type: '',
    },
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    form() {
      let formData = new FormData()
      if (this.editedIndex > -1) {
        formData.append('_method', 'PUT')
      }
      formData.append('name', this.editedItem.name)
      formData.append('designation', this.editedItem.designation)
      formData.append('advice', this.editedItem.advice)
      formData.append('hr_type', this.editedItem.hr_type)
      formData.append('subject', this.editedItem.subject)
      formData.append('photo', this.photo)
      return formData
    }
  },

  watch: {
    "editedItem.hr_type": function (newVal) {
      if (newVal !== 'teacher') this.editedItem.subject = '';
      else if (newVal !== 'advisor') this.editedItem.advice = '';
    },
    hr_type_filter: function (newVal) {
      const url = '/hr?hr_type=' + newVal
      axios.get(url).then((response) => {
        this.hrs = response.data
      }).catch(() => {

      })
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    hrs() {
      this.sortedHrs = this.hrs
    },
    sortedHrs(newVal, oldVal) {
      if (oldVal.length) {
        this.sortItems()
      }
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.loading = true;
      const url = '/hr'
      axios.get(url).then((response) => {
        this.hrs = response.data
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
      })
    },

    editItem(item) {
      this.editedIndex = this.hrs.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.hrs.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const url = 'admin/hr/' + this.editedItem.id
      let editedIndex = this.editedIndex
      axios.delete(url).then(() => {
        this.hrs.splice(editedIndex, 1)
        this.closeDelete()
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.photo = null
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    sortItems() {
      const url = 'admin/arrange-hr-order'
      let data = {
        courses: this.sortedHrs,
        type: 'sort'
      }
      axios.post(url, data).then(() => {
      })
    },
    uniqueId() {
      return uuidv4.v4()
    },
    save() {
      if (this.editedIndex > -1) {
        const url = 'admin/hr/' + this.editedItem.id
        let editedIndex = this.editedIndex
        axios.post(url, this.form).then((response) => {
          Object.assign(this.hrs[editedIndex], response.data)
        }).then(() => {
          this.close()
        })
      } else {
        const url = 'admin/hr'
        axios.post(url, this.form).then((response) => {
          this.hrs.push(response.data)
        }).then(() => {
          this.close()
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
